import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../../../utils/theme'
import { SmallPageSection } from '../layout/PageComponents'
import ToolTip from '../other/ToolTip'
import Diamond from '../svg_icons/Diamond'

export const Checkbox = styled.input`
	outline: none;
	appearance: none;
	min-width: 20px;
	height: 20px;
	border-radius: 2px;
	border: 2px solid ${theme.colors.gray};
	${({ invalidFormat }) => (invalidFormat ? `border: 1px solid ${theme.colors.errorColor} !important;` : '')}
	background: ${theme.colors.lightGray};
	padding: 4px;
	::after {
		content: '';
		height: 100%;
		background: ${props => (props.checked ? theme.colors.black : 'none')};
		display: block;
		border-radius: 2px;
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		:hover {
			border: 2px solid ${theme.colors.gray};
			${({ invalidFormat }) => (invalidFormat ? `border: 1px solid ${theme.colors.errorColor} !important;` : '')}
			::after {
				background: ${props => (props.checked ? theme.colors.black : theme.colors.gray)};
			}
		}
	}
`
const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
`
export const CheckboxLabel = styled.label`
	margin-left: ${theme.margins.XSmall};
	cursor: pointer;
	${({ premium }) =>
		premium
			? css`
					display: flex;
					align-items: center;
				`
			: ''};
	div,
	p {
		display: inline;
	}
	> p {
		${({ disabled }) =>
			disabled
				? css`
						opacity: 0.5;
					`
				: ''};
	}
`
const CheckboxInput = React.memo(
	React.forwardRef(function checkboxInput(
		{ label, labelElement, required, skipRequiredAsterisk, name, checked, hidden, disabled, premium, t, ...props },
		ref,
	) {
		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])
		return (
			<SmallPageSection style={{ display: hidden ? 'none' : 'block' }}>
				<CheckboxContainer id={name + 'Parent'} disableInput={disabled}>
					<Checkbox
						type="checkbox"
						required={required}
						name={name}
						ref={ref}
						id={name}
						disabled={disabled}
						checked={!!checked}
						{...props}
					/>
					{label && (
						<CheckboxLabel htmlFor={name} premium={premium} disabled={disabled}>
							{premium && (
								<>
									<ToolTip name={name + 'Diamond'} icon={<Diamond width={16} height={16} />}>
										{t('admin:upgradeTier.requiresStandard')}
									</ToolTip>
									&nbsp;
								</>
							)}
							<p>
								{label} {required && !skipRequiredAsterisk && '*'}
							</p>
						</CheckboxLabel>
					)}
					{labelElement && (
						<CheckboxLabel htmlFor={name}>
							{labelElement} {required && !skipRequiredAsterisk && '*'}
						</CheckboxLabel>
					)}
				</CheckboxContainer>
			</SmallPageSection>
		)
	}),
)

CheckboxInput.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	labelElement: PropTypes.object, // A html element can be chosen instead of a text label
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	required: PropTypes.bool,
	skipRequiredAsterisk: PropTypes.bool,
}

export default CheckboxInput
