import React from 'react'
import SvgShell from './SvgShell'

const Calendar = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 21 23">
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 1.5 }}
				d="M2,2.8h17c0.7,0,1.2,0.6,1.2,1.2v17c0,0.7-0.6,1.2-1.2,1.2H2c-0.7,0-1.2-0.6-1.2-1.2V4C0.8,3.3,1.3,2.8,2,2.8z"
			/>
			<rect x="0.9" y="3" width="19.2" height="4" />
			<path
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
				}}
				d="M5.5,1v3"
			/>
			<path
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
				}}
				d="M15.5,1v3"
			/>
		</svg>
	</SvgShell>
)

export default Calendar
