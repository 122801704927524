import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from '../../../utils/theme'
import { Markdown } from '../Typography'
import CheckboxInput from '../forms/CheckboxInput'
import DateInput from '../forms/DateInput'
import ImageInput from '../forms/ImageInput'
import MapInput from '../forms/MapInput'
import SelectInput from '../forms/SelectInput'
import TextAreaInput from '../forms/TextAreaInput'
import TextInput from '../forms/TextInput'
import TimeInput from '../forms/TimeInput'

export const ToggleableElementWrapper = styled.div`
	> *:first-child {
		padding-top: ${({ skipPadding }) => (skipPadding ? 0 : theme.paddings.small)};
	}
`

// Use to hide and show an element (children) by clicking on another element (toggler)
// toggler can be either a react element or a function. If it is a function, it will have access to the 'toggled' state
const ToggleableElement = ({
	children,
	toggler,
	toggleOnMount = false,
	skipPadding,
	optionalStyle,
	parentTogglers = [],
}) => {
	const [toggled, setToggled] = useState(toggleOnMount)
	if (!React.isValidElement(toggler) && typeof toggler !== 'function') return null

	// This recursiveWrapper is built for when a ToggleableElement is in a Form. If it isn't in a Form, it still runs but doesn't provide anything useful
	const recursiveWrapper = children =>
		React.Children.map(children, child => {
			if (!child) return
			if (child.type === ToggleableElement) {
				// Child is another ToggleableElement, so pass on parentTogglers so if that child has an form input element it can pass all parentTogglers to it
				return React.cloneElement(
					child,
					{ parentTogglers: [setToggled, ...parentTogglers] },
					recursiveWrapper(child.props.children),
				)
			} else if (
				[CheckboxInput, DateInput, ImageInput, MapInput, SelectInput, TextAreaInput, TextInput, TimeInput].includes(
					child.type,
				)
			) {
				// Child is a form input, so give it access to setToggled so they can open this ToggleableElement and scroll to it if they are invalid for form submission
				return React.cloneElement(child, { parentTogglers: [setToggled, ...parentTogglers] })
			} else if (child.props && child.props.children && child.type !== Markdown) {
				return React.cloneElement(child, {}, recursiveWrapper(child.props.children))
			} else {
				return child
			}
		})

	return (
		<>
			<div style={{ cursor: 'pointer' }} onClick={() => setToggled(!toggled)}>
				{React.isValidElement(toggler) ? toggler : toggler(toggled)}
			</div>
			<ToggleableElementWrapper
				skipPadding={skipPadding}
				style={{ display: toggled ? 'block' : 'none', ...optionalStyle }}
			>
				{recursiveWrapper(children)}
			</ToggleableElementWrapper>
		</>
	)
}

ToggleableElement.propTypes = {
	toggler: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
	skipPadding: PropTypes.bool,
}

export default ToggleableElement
