import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { SmallPageSection } from '../layout/PageComponents'
import { CheckboxLabel } from './CheckboxInput'
import CrocodileDown from '../svg_icons/CrocodileDown'
import { theme } from '../../../utils/theme'
import { FormLabel, standardInputStyling } from './TextInput'
import ToolTip from '../other/ToolTip'

const FieldInput = styled.select`
	${({ invalidFormat }) => css`
		${standardInputStyling(invalidFormat)}
		padding-right: 40px;
		background: transparent;
		z-index: 1;
		cursor: pointer;
		option[disabled] {
			display: none;
		}
	`}
`
const SelectContainer = styled.div`
	position: relative;
	height: 62px;
	background: white;
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : 'none')};
	> * {
		position: absolute;
		:first-child {
			left: 0;
			top: 0;
		}
		:last-child {
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
`
export const ColoredInputBackground = styled.div`
	width: 100%;
	height: 100%;
	background: ${theme.colors.gray};
`
// Only update when props update using memoization for performance
const SelectInput = React.memo(
	React.forwardRef(function selectInput(
		{
			label,
			labelElement,
			skipLabel,
			required,
			skipRequiredAsterisk,
			name,
			value,
			title,
			hidden,
			options,
			placeholder,
			maxWidth,
			tooltip,
			...props
		},
		ref,
	) {
		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])
		return (
			<SmallPageSection style={{ display: hidden ? 'none' : 'block' }} id={name + 'Parent'}>
				{!skipLabel && label && (
					<FormLabel>
						<div>
							{label} {required && !skipRequiredAsterisk && '*'}
						</div>
						{tooltip && (
							<ToolTip name={name} place="top">
								{tooltip}
							</ToolTip>
						)}
					</FormLabel>
				)}
				{!skipLabel && labelElement && (
					<CheckboxLabel htmlFor={name}>
						{labelElement} {required && !skipRequiredAsterisk && '*'}
					</CheckboxLabel>
				)}
				<SelectContainer maxWidth={maxWidth}>
					<FieldInput
						id={name}
						ref={ref}
						required={required}
						name={name}
						value={value || ''}
						type={value || 'text'}
						{...props}
					>
						{placeholder && (
							<option value="" disabled>
								{placeholder}
							</option>
						)}
						{options.map(({ key, code, name, value, title }) => {
							return (
								<option key={key || code} value={key || code} title={title}>
									{value || name}
								</option>
							)
						})}
					</FieldInput>
					<ColoredInputBackground />
					<CrocodileDown />
				</SelectContainer>
			</SmallPageSection>
		)
	}),
)

SelectInput.propTypes = {
	label: PropTypes.string,
	labelElement: PropTypes.object, // A html element can be chosen instead of a text label
	tooltip: PropTypes.string, // Add a tooltip text
	skipLabel: PropTypes.bool, // Don't show a label
	name: PropTypes.string.isRequired, // Main identifier. Needs to be unique for the form
	desiredName: PropTypes.string, // Set to e.g. "firstName" if another input with "firstName" already exists
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string, // THIS IS REQUIRED IF NO VALUE IS ENTERED
	required: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	skipRequiredAsterisk: PropTypes.bool,
	invalidFormat: PropTypes.bool,
	options: PropTypes.array.isRequired,
}

export default SelectInput
