import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { SmallPageSection } from '../layout/PageComponents'
import { CheckboxLabel } from './CheckboxInput'
import { theme } from '../../../utils/theme'
import ToolTip from '../other/ToolTip'
import Diamond from '../svg_icons/Diamond'

export const standardInputStyling = invalidFormat => css`
	-webkit-appearance: none;
	padding: ${theme.paddings.small};
	line-height: ${theme.lineHeights.p4};
	width: 100%;
	background: ${theme.colors.gray};
	border-radius: 2px;
	outline: none;
	border: 1px solid transparent;
	${invalidFormat ? `border: 1px solid ${theme.colors.errorColor} !important;` : ''}
	::placeholder {
		color: ${theme.colors.lightTextColor};
	}
`
export const FormLabel = styled.div`
	margin-bottom: ${theme.margins.XSmall};
	display: flex;
	align-items: center;
	> div:not(:first-child) {
		margin-left: ${theme.margins.XSmall};
	}
`

const FieldInput = styled.input`
	${({ invalidFormat }) => standardInputStyling(invalidFormat)}
`
// Only update when props update using memoization for performance
const TextInput = React.memo(
	React.forwardRef(function textInput(
		{
			type,
			label,
			labelElement,
			skipLabel,
			required,
			skipRequiredAsterisk,
			name,
			value,
			hidden,
			tooltip,
			t,
			premium,
			...props
		},
		ref,
	) {
		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])
		return (
			<SmallPageSection style={{ display: hidden ? 'none' : 'block' }} id={name + 'Parent'}>
				{!skipLabel && label && (
					<FormLabel>
						<div>
							{label} {required && !skipRequiredAsterisk && '*'}
						</div>
						{premium && (
							<ToolTip name={name + 'Diamond'} icon={<Diamond width={16} height={16} />}>
								{t('admin:upgradeTier.requiresStandard')}
							</ToolTip>
						)}
						{tooltip && (
							<ToolTip name={name} place="top">
								{tooltip}
							</ToolTip>
						)}
					</FormLabel>
				)}
				{!skipLabel && labelElement && (
					<CheckboxLabel htmlFor={name}>
						{labelElement} {required && !skipRequiredAsterisk && '*'}
					</CheckboxLabel>
				)}
				<FieldInput
					id={name}
					ref={ref}
					required={required}
					name={name}
					value={typeof value === 'undefined' ? '' : value}
					type={type || 'text'}
					data-type={type || 'text'} // Some browsers don't support all type attributes, so we access this info from a data attribute instead as a fallback
					onWheel={
						type === 'number'
							? e => {
									e.target.blur() // Disable changing the number on scroll, since this is easily made by accident
							  }
							: null
					}
					min={0}
					{...props}
				/>
			</SmallPageSection>
		)
	}),
)

TextInput.propTypes = {
	// type: PropTypes.oneOf(['text', 'number', 'tel', 'email', 'password']),
	label: PropTypes.string,
	tooltip: PropTypes.string, // Add a tooltip text
	labelElement: PropTypes.object, // A html element can be chosen instead of a text label
	skipLabel: PropTypes.bool, // Don't show a label
	name: PropTypes.string.isRequired, // Main identifier. Needs to be unique for the form
	desiredName: PropTypes.string, // Set to e.g. "firstName" if another input with "firstName" already exists
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	skipRequiredAsterisk: PropTypes.bool,
	invalidFormat: PropTypes.bool,
}

export default TextInput
