import React from 'react'
import SvgShell from './SvgShell'

const Clock = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
			<rect width="256" height="256" fill="none" />
			<circle cx="128" cy="128" r="96" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="16" />
			<polyline
				points="128 72 128 128 184 128"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="16"
			/>
		</svg>
	</SvgShell>
)

export default Clock
