import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { FormLabel, standardInputStyling } from './TextInput'
import { SmallPageSection } from '../layout/PageComponents'
import { CheckboxLabel } from './CheckboxInput'
import { theme } from '../../../utils/theme'
import Clock from '../svg_icons/Clock'

export const InputWrapper = styled.div`
	position: relative;
	> svg {
		position: absolute;
		cursor: pointer;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		background: ${({ nonWhiteBackground }) => (nonWhiteBackground ? 'white' : theme.colors.gray)};
	}
`
const FieldInput = styled.input`
	${({ invalidFormat }) => css`
		${standardInputStyling(invalidFormat)}
		padding-top: 19px;
		padding-bottom: 19px;
	`}
`

// Only update when props update using memoization for performance
const TimeInput = React.memo(
	React.forwardRef(function Input(
		{
			label,
			labelElement,
			required,
			skipRequiredAsterisk,
			name,
			value,
			onChange,
			hidden,
			placeholder,
			nonWhiteBackground,
			...props
		},
		ref,
	) {
		const [timePickerState, setTimePickerState] = useState(null)
		const timePickerRef = useRef(null)

		useEffect(() => {
			// Click listener to close the calendar when a click is made outside of it
			const clickListener = event => {
				if (timePickerRef.current !== event.target && !timePickerRef.current?.contains(event.target)) {
					window.removeEventListener('click', clickListener)
					setTimePickerState(null)
				}
			}
			if (timePickerState) {
				window.addEventListener('click', clickListener)
			}
			return () => window.removeEventListener('click', clickListener)
		}, [timePickerState])

		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])

		return (
			<SmallPageSection style={{ display: hidden ? 'none' : 'block' }} id={name + 'Parent'}>
				{label && (
					<FormLabel>
						{label} {required && !skipRequiredAsterisk && '*'}
					</FormLabel>
				)}
				{labelElement && (
					<CheckboxLabel htmlFor={name}>
						{labelElement} {required && !skipRequiredAsterisk && '*'}
					</CheckboxLabel>
				)}
				<InputWrapper nonWhiteBackground={nonWhiteBackground}>
					<FieldInput
						id={name}
						value={value}
						name={name}
						required
						type="text"
						data-type="time" // Some browsers don't support all type attributes, so we access this info from a data attribute instead as a fallback
						onChange={onChange}
						ref={ref}
						{...props}
						placeholder="hh:mm"
						pattern="[0-9]{2}:[0-9]{2}"
						step="60"
					/>
					<Clock
						onClick={event => {
							event.stopPropagation()
							setTimePickerState(timePickerState ? null : 'HOUR')
						}}
					/>
					<ClockWrapper timePickerState={timePickerState} ref={timePickerRef}>
						<HourMinutesPopup
							time={value}
							onChange={onChange}
							timePickerState={timePickerState}
							setTimePickerState={setTimePickerState}
							inputRef={ref}
						/>
					</ClockWrapper>
				</InputWrapper>
			</SmallPageSection>
		)
	}),
)

const ClockWrapper = styled.div`
	top: 60px;
	right: 0;
	position: absolute;
	border-radius: 5px;
	height: 270px;
	width: 270px;
	z-index: 2;
	background: white;
	justify-content: center;
	align-items: center;
	box-shadow: #00000017 1px 1px 9px 0;
	display: ${({ timePickerState }) => (timePickerState ? 'flex' : 'none')};
`
const ClockCircle = styled.div`
	background-color: ${theme.colors.gray};
	border-radius: 50%;
	height: 220px;
	width: 220px;
	position: relative;
`
const ClockDot = styled.div`
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: ${theme.colors.orange};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`
const ClockValue = styled.span`
	height: 36px;
	width: 36px;
	position: absolute;
	left: calc((100% - 36px) / 2);
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
	user-select: none;
	display: ${({ show }) => (show ? 'inline-flex' : 'none')};
	color: ${({ chosen }) => (chosen ? 'white' : theme.colors.textColor)};
	transform: translate(${({ coordinates }) => coordinates});
`
const ClockPointer = styled.div`
	width: 2px;
	background-color: ${theme.colors.orange};
	position: absolute;
	left: calc(50% - 1px);
	bottom: 50%;
	transform-origin: center bottom 0;
	height: ${({ height }) => height}px;
	transform: rotateZ(${({ rotate }) => rotate}deg);
	> div {
		width: 4px;
		height: 4px;
		background-color: ${theme.colors.orange};
		border-radius: 50%;
		position: absolute;
		top: -21px;
		left: calc(50% - 18px);
		border: 16px solid ${theme.colors.orange};
		box-sizing: content-box;
	}
`

const HourMinutesPopup = ({ time, onChange, timePickerState, setTimePickerState, inputRef }) => {
	const timeArray = time.split(':')
	const currentHour = timeArray[0]
	const currentMinute = timeArray[1]
	const currentDataset = timePickerState === 'HOUR' ? hours : minutes
	const currentDatasetKey = timePickerState === 'HOUR' ? currentHour : currentMinute
	return (
		<ClockCircle>
			<ClockDot />
			{currentDataset[currentDatasetKey]?.height && (
				<ClockPointer
					height={currentDataset[currentDatasetKey].height}
					rotate={currentDataset[currentDatasetKey].rotate}
				>
					<div />
				</ClockPointer>
			)}
			{/* Keep both hour and minute values in the DOM at the same time so the click listener's "contains" works */}
			{Object.entries(hours).map(([hour, data], key) => {
				return (
					<ClockValue
						show={timePickerState === 'HOUR'}
						chosen={currentHour === hour}
						coordinates={data.coordinates}
						key={key}
						onClick={() => {
							inputRef.current.value = hour + ':' + (currentMinute || '00')
							onChange({ target: inputRef.current })
							setTimePickerState('MINUTE')
						}}
					>
						{hour}
					</ClockValue>
				)
			})}
			{Object.entries(minutes).map(([minute, data], key) => {
				return (
					<ClockValue
						show={timePickerState === 'MINUTE'}
						chosen={currentMinute === minute}
						coordinates={data.coordinates}
						key={key}
						onClick={() => {
							inputRef.current.value = currentHour + ':' + minute
							onChange({ target: inputRef.current })
							setTimePickerState(null)
						}}
					>
						{minute}
					</ClockValue>
				)
			})}
		</ClockCircle>
	)
}

const hours = {
	'00': { rotate: 0, height: 57, coordinates: '0px, 33px' },
	'01': { rotate: 30, height: 88, coordinates: '45px, 13px' },
	'02': { rotate: 60, height: 88, coordinates: '79px, 47px' },
	'03': { rotate: 90, height: 88, coordinates: '91px, 92px' },
	'04': { rotate: 120, height: 88, coordinates: '79px, 137px' },
	'05': { rotate: 150, height: 88, coordinates: '45px, 171px' },
	'06': { rotate: 180, height: 88, coordinates: '0px, 183px' },
	'07': { rotate: 210, height: 88, coordinates: '-46px, 171px' },
	'08': { rotate: 240, height: 88, coordinates: '-79px, 138px' },
	'09': { rotate: 270, height: 88, coordinates: '-91px, 92px' },
	10: { rotate: 300, height: 88, coordinates: '-79px, 46px' },
	11: { rotate: 330, height: 88, coordinates: '-46px, 13px' },
	12: { rotate: 0, height: 88, coordinates: '0px, 1px' },
	13: { rotate: 30, height: 57, coordinates: '30px, 41px' },
	14: { rotate: 60, height: 57, coordinates: '51px, 62px' },
	15: { rotate: 90, height: 57, coordinates: '59px, 92px' },
	16: { rotate: 120, height: 57, coordinates: '51px, 122px' },
	17: { rotate: 150, height: 57, coordinates: '30px, 143px' },
	18: { rotate: 180, height: 57, coordinates: '0px, 151px' },
	19: { rotate: 210, height: 57, coordinates: '-30px, 143px' },
	20: { rotate: 240, height: 57, coordinates: '-51px, 122px' },
	21: { rotate: 270, height: 57, coordinates: '-59px, 92px' },
	22: { rotate: 300, height: 57, coordinates: '-51px, 62px' },
	23: { rotate: 330, height: 57, coordinates: '-30px, 41px' },
}
const minutes = {
	'00': { rotate: 0, height: 88, coordinates: '0px, 1px' },
	'05': { rotate: 30, height: 88, coordinates: '45px, 13px' },
	10: { rotate: 60, height: 88, coordinates: '79px, 47px' },
	15: { rotate: 90, height: 88, coordinates: '91px, 92px' },
	20: { rotate: 120, height: 88, coordinates: '79px, 137px' },
	25: { rotate: 150, height: 88, coordinates: '45px, 171px' },
	30: { rotate: 180, height: 88, coordinates: '0px, 183px' },
	35: { rotate: 210, height: 88, coordinates: '-46px, 171px' },
	40: { rotate: 240, height: 88, coordinates: '-79px, 138px' },
	45: { rotate: 270, height: 88, coordinates: '-91px, 92px' },
	50: { rotate: 300, height: 88, coordinates: '-79px, 46px' },
	55: { rotate: 330, height: 88, coordinates: '-46px, 13px' },
}

TimeInput.propTypes = {
	label: PropTypes.string,
	labelElement: PropTypes.object, // A html element can be chosen instead of a text label
	name: PropTypes.string.isRequired, // Main identifier. Needs to be unique for the form
	desiredName: PropTypes.string, // Set to e.g. "firstName" if another input with "firstName" already exists
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	required: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	skipRequiredAsterisk: PropTypes.bool,
	invalidFormat: PropTypes.bool,
}

export default TimeInput
