import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SmallPageSection } from '../layout/PageComponents'
import { B4 } from '../Typography'
import { CheckboxLabel } from './CheckboxInput'
import useScript from '../../../hooks/useScript'
import { standardInputStyling } from './TextInput'
import { getApiKeyStore } from '../../../utils/applicationHelper'

const FieldInput = styled.input`
	${({ invalidFormat }) => standardInputStyling(invalidFormat)}
`
const Map = styled.div`
	height: 350px;
	width: 100%;
	max-width: 500px;
	margin: 15px 0;
	${({ theme }) => theme.breakpoints.phone.upToAndExcluding} {
		width: 100%;
	}
`
// Only update when props update using memoization for performance
const MapInput = React.memo(
	React.forwardRef(function textInput(
		{ miscData, label, labelElement, onChange, required, skipRequiredAsterisk, name, value, hidden, valid, ...props },
		ref,
	) {
		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])
		const [startedWriting, setStartedWriting] = useState()
		useEffect(() => {
			// The user can select the location from a dropdown menu with the keyboard arrows and then hit enter.
			// Pressing enter will also submit the form though, so prevent it from submitting if the target is the map input field
			// and the key is the normal (13) or numpad (108) enter button
			document.addEventListener('keydown', function (event) {
				if ((event.keyCode === 13 || event.keyCode === 108) && event.target.id === name) {
					event.preventDefault()
					return false
				}
			})
		}, [])
		useScript(
			`https://maps.googleapis.com/maps/api/js?key=${getApiKeyStore().GOOGLE_MAPS_SDK}&libraries=places`,
			event => {
				const { latitude, longitude } = miscData
				const latlng = new google.maps.LatLng(latitude, longitude)
				const image = 'https://www.google.com/intl/en_us/mapfiles/ms/micons/blue-dot.png'
				const mapOptions = {
						center: new google.maps.LatLng(latitude, longitude),
						zoom: 15,
						mapTypeControl: false,
						streetViewControl: false,
						panControl: true,
						panControlOptions: {
							position: google.maps.ControlPosition.TOP_RIGHT,
						},
						zoomControl: false,
						zoomControlOptions: {
							style: google.maps.ZoomControlStyle.LARGE,
							position: google.maps.ControlPosition.TOP_left,
						},
					},
					map = new google.maps.Map(document.getElementById(name + 'Map'), mapOptions),
					marker = new google.maps.Marker({
						position: latlng,
						map: map,
						icon: image,
					})
				const input = document.getElementById(name)
				const autocomplete = new google.maps.places.Autocomplete(input, {
					types: ['geocode', 'establishment'],
					location: { lat: 59, lng: 18 },
				})
				autocomplete.bindTo('bounds', map)
				const infowindow = new google.maps.InfoWindow()
				google.maps.event.addListener(autocomplete, 'place_changed', function (event) {
					infowindow.close()
					const place = autocomplete.getPlace()
					if (!place.geometry) return
					if (place.geometry.viewport) {
						map.fitBounds(place.geometry.viewport)
						map.setZoom(15)
					} else {
						map.setCenter(place.geometry.location)
						map.setZoom(15)
					}
					moveMarker(place.name, place.geometry.location)
					onChange(
						{ target: input },
						{
							latitude: place.geometry.location.lat(),
							longitude: place.geometry.location.lng(),
							newAddressFound: true,
							googleAddress: place.formatted_address,
						},
					)
				})
				function moveMarker(placeName, latlng) {
					marker.setIcon(image)
					marker.setPosition(latlng)
					infowindow.setContent(placeName)
				}
			},
		)
		let invalidFormat = false
		if (startedWriting) {
			if (required) {
				if (value.length === 0 || !valid) invalidFormat = true
			} else {
				if (value.length > 0 && !valid) invalidFormat = true
			}
		}
		return (
			<SmallPageSection style={{ display: hidden ? 'none' : 'block' }} id={name + 'Parent'}>
				{label && (
					<B4 style={{ textTransform: 'uppercase' }}>
						{label} {required && !skipRequiredAsterisk && '*'}
					</B4>
				)}
				{labelElement && (
					<CheckboxLabel htmlFor={name}>
						{labelElement} {required && !skipRequiredAsterisk && '*'}
					</CheckboxLabel>
				)}
				<FieldInput
					id={name}
					ref={ref}
					onChange={e => {
						onChange(e)
						setStartedWriting(true)
					}}
					required={required}
					name={name}
					value={value || ''}
					type="map"
					invalidFormat={invalidFormat}
					{...props}
				/>
				<Map id={name + 'Map'} />
			</SmallPageSection>
		)
	}),
)

MapInput.propTypes = {
	// type: PropTypes.oneOf(['text', 'number', 'tel', 'email']),
	label: PropTypes.string,
	labelElement: PropTypes.object, // A html element can be chosen instead of a text label
	name: PropTypes.string.isRequired, // Main identifier. Needs to be unique for the form
	desiredName: PropTypes.string, // Set to e.g. "firstName" if another input with "firstName" already exists
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	skipRequiredAsterisk: PropTypes.bool,
	invalidFormat: PropTypes.bool,
	miscData: PropTypes.exact({
		latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		newAddressFound: PropTypes.bool, // Is true when the address value is something returned by Google, as opposed to the user typing, i.e. we have a new googleAddress
		googleAddress: PropTypes.string, // The address found and formatted by Google. This is the one we want to submit
	}),
}

export default MapInput
