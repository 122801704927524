import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import i18n from 'i18next'
import { FormLabel, standardInputStyling } from './TextInput'

import { SmallPageSection } from '../layout/PageComponents'
import { CheckboxLabel } from './CheckboxInput'
import Pickadate from 'pickadate/builds/react-dom'
import Calendar from '../svg_icons/Calendar'
import { ColoredInputBackground } from './SelectInput'

const FieldInput = styled.input`
	${({ invalidFormat }) => css`
		${standardInputStyling(invalidFormat)}
		padding-right: 40px;
		background: transparent;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		cursor: pointer;
	`}
`
export const DatePickerWrapper = styled.div`
	position: relative;
	height: 60px;
	> * {
		position: absolute;
		:last-child {
			display: ${({ displayDatePicker }) => (displayDatePicker ? 'block' : 'none')};
			top: 60px;
			z-index: 2;
			@media only screen and (max-width: 340px) {
				left: -20px; // Make calendar popup fit on really small mobile
			}
		}
	}
	> svg {
		cursor: pointer;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
	}
`
// Only update when props update using memoization for performance
const DateInput = React.memo(
	React.forwardRef(function Input(
		{ label, labelElement, required, skipRequiredAsterisk, name, value, hidden, onChange, placeholder, ...props },
		ref,
	) {
		const [displayDatePicker, setDisplayDatePicker] = useState(false)
		const datePickerRef = useRef(null)

		useEffect(() => {
			// Click listener to close the calendar when a click is made outside of it
			const clickListener = event => {
				if (datePickerRef.current !== event.target && !datePickerRef.current?.contains(event.target)) {
					window.removeEventListener('click', clickListener)
					setDisplayDatePicker(false)
				}
			}
			if (displayDatePicker) {
				window.addEventListener('click', clickListener)
			}
			return () => window.removeEventListener('click', clickListener)
		}, [displayDatePicker])
		const onClick = event => {
			event.stopPropagation()
			setDisplayDatePicker(!displayDatePicker)
		}
		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])
		return (
			<SmallPageSection style={{ display: hidden ? 'none' : 'block' }} id={name + 'Parent'}>
				{label && (
					<FormLabel>
						{label} {required && !skipRequiredAsterisk && '*'}
					</FormLabel>
				)}
				{labelElement && (
					<CheckboxLabel htmlFor={name}>
						{labelElement} {required && !skipRequiredAsterisk && '*'}
					</CheckboxLabel>
				)}
				<DatePickerWrapper displayDatePicker={displayDatePicker}>
					<FieldInput
						onClick={onClick}
						id={name}
						ref={ref}
						required={required}
						name={name}
						value={value || ''}
						type="text" // Needs to be text so iOS doesn't use its native date picker
						readOnly
						placeholder={placeholder || 'yyyy-mm-dd'}
						{...props}
					/>
					<ColoredInputBackground />
					<Calendar onClick={onClick} />
					<div ref={datePickerRef}>
						<Pickadate.DatePicker
							options={{
								mode: 'date',
							}}
							initialState={{
								minimum: new Date(),
								selected: value ? new Date(value) : null,
							}}
							initialTranslation={{
								firstDayOfWeek: 1,
								template: 'YYYY-MM-DD',
								templateHookWords: i18n.language === 'sv' && {
									MMM: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
									MMMM: [
										'Januari',
										'Februari',
										'Mars',
										'April',
										'Maj',
										'Juni',
										'Juli',
										'Augusti',
										'September',
										'Oktober',
										'November',
										'December',
									],
									DDD: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
									DDDD: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
								},
							}}
							onChangeValue={({ date, value }) => {
								ref.current.value = value
								onChange({ target: ref.current })
								setDisplayDatePicker(false)
							}}
						/>
					</div>
				</DatePickerWrapper>
			</SmallPageSection>
		)
	}),
)

DateInput.propTypes = {
	// type: PropTypes.oneOf(['text', 'number', 'tel', 'email']),
	label: PropTypes.string,
	labelElement: PropTypes.object, // A html element can be chosen instead of a text label
	name: PropTypes.string.isRequired, // Main identifier. Needs to be unique for the form
	desiredName: PropTypes.string, // Set to e.g. "firstName" if another input with "firstName" already exists
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	skipRequiredAsterisk: PropTypes.bool,
	invalidFormat: PropTypes.bool,
}

export default DateInput
