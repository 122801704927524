import React from 'react'
import SvgShell from './SvgShell'
import { theme } from '../../../utils/theme'

const InfoNew = props => {
	const { fillColor = 'black', textColor = 'white' } = props
	return (
		<SvgShell {...props}>
			<circle style={{ fill: theme.colors[fillColor] }} cx="10.4" cy="10.8" r="10" />
			<rect style={{ fill: theme.colors[textColor] }} x="9.5" y="9.9" width="1.8" height="5.5" />
			<rect style={{ fill: theme.colors[textColor] }} x="9.5" y="6.2" width="1.8" height="1.8" />
		</SvgShell>
	)
}

export default InfoNew
