import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { SmallPageSection } from '../layout/PageComponents'
import { CheckboxLabel } from './CheckboxInput'
import { FormLabel, standardInputStyling } from './TextInput'
import ToolTip from '../other/ToolTip'

const FieldInput = styled.textarea`
	${({ invalidFormat }) => css`
		${standardInputStyling(invalidFormat)}
		min-height: 150px;
		resize: vertical;
	`}
`
// Only update when props update using memoization for performance
const TextAreaInput = React.memo(
	React.forwardRef(function textInput(
		{
			label,
			labelElement,
			skipLabel,
			required,
			skipRequiredAsterisk,
			name,
			value,
			hidden,
			tooltip,
			tooltipPlace,
			...props
		},
		ref,
	) {
		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])
		return (
			<SmallPageSection style={{ display: hidden ? 'none' : 'block' }} id={name + 'Parent'}>
				{!skipLabel && label && (
					<FormLabel>
						<div>
							{label} {required && !skipRequiredAsterisk && '*'}
						</div>
						{tooltip && (
							<ToolTip name={name} place={tooltipPlace}>
								{tooltip}
							</ToolTip>
						)}
					</FormLabel>
				)}
				{!skipLabel && labelElement && (
					<CheckboxLabel htmlFor={name}>
						{labelElement} {required && !skipRequiredAsterisk && '*'}
					</CheckboxLabel>
				)}
				<FieldInput
					id={name}
					ref={ref}
					required={required}
					name={name}
					value={value || ''}
					type={value || 'text'}
					{...props}
				/>
			</SmallPageSection>
		)
	}),
)

TextAreaInput.propTypes = {
	label: PropTypes.string,
	labelElement: PropTypes.object, // A html element can be chosen instead of a text label
	skipLabel: PropTypes.bool, // Don't show a label
	name: PropTypes.string.isRequired, // Main identifier. Needs to be unique for the form
	desiredName: PropTypes.string, // Set to e.g. "firstName" if another input with "firstName" already exists
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	skipRequiredAsterisk: PropTypes.bool,
	invalidFormat: PropTypes.bool,
}

export default TextAreaInput
