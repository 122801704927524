import React from 'react'
import SvgShell from './SvgShell'

const CrocodileUp = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
			<path style={{ fill: props.fill || 'currentColor' }} d="m7.4 15.375-1.4-1.4 6-6 6 6-1.4 1.4-4.6-4.6Z" />
		</svg>
	</SvgShell>
)

export default CrocodileUp
