import { useEffect } from 'react'

// Some packages are not officially added to npm. This hook can load those scripts when mounted, so we don't need to add them to <head> since that would load them for every page.
// Use the callback to know when it has finished loading.

const scriptCache = {}

const useScript = (url, callback) => {
	useEffect(() => {
		if (scriptCache.url) {
			// Script has already been loaded, so only call callback and don't load script again
			callback()
			return
		} else {
			scriptCache.url = true
		}
		const script = document.createElement('script')
		script.src = url
		script.async = true
		script.addEventListener('load', callback)
		document.body.appendChild(script)
		return () => {
			// Called when component is unmounted
			document.body.removeChild(script)
		}
	}, [url])
}

export default useScript
