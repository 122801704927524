import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import { getCloudinaryImageUrl, isLocalImage } from '../../../utils/imageHelper'

import { SmallPageSection } from '../layout/PageComponents'
import ToolTip from '../other/ToolTip'

const Label = styled.label`
	min-width: 200px;
	display: block;
	text-align: center;
	padding: ${theme.paddings.XSmall} ${theme.paddings.small};
	cursor: pointer;
	line-height: 1;
	border-radius: 100px;
	border: 1px solid ${theme.colors.black};
	color: ${theme.colors.textColor};
	position: relative;
	${({ centered }) => centered && 'margin: auto;'}
`
const TooltipWrapper = styled.div`
	position: absolute;
	right: -30px;
	top: 7px;
`
const FieldInputHidden = styled.input`
	display: none;
`
// Only update when props update using memoization for performance
const ImageInput = React.memo(
	React.forwardRef(function imageInput(
		{ label, required, skipRequiredAsterisk, name, hidden, value, imageType, tooltip, tooltipPlace, ...props },
		ref,
	) {
		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])
		return (
			<SmallPageSection>
				{value && (
					<img
						style={{
							width: '200px',
							borderRadius: imageType === 'PROFILE' && !isLocalImage(value) ? '50%' : 0,
							boxShadow: '#00000017 1px 1px 9px 0px',
						}}
						src={isLocalImage(value) ? value : getCloudinaryImageUrl(value, 300, imageType)} // If the value starts with data:image it is an image from the user's computer loaded through the FileReader. Otherwise it is a cloudinary image
					/>
				)}
				<SmallPageSection style={{ display: hidden ? 'none' : 'block' }} id={name + 'Parent'}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Label htmlFor={name} {...props}>
							{label} {required && !skipRequiredAsterisk && '*'}
							{tooltip && (
								<TooltipWrapper>
									<ToolTip name={name + 'Tooltip'} place={tooltipPlace}>
										{tooltip}
									</ToolTip>
								</TooltipWrapper>
							)}
						</Label>
					</div>
					<FieldInputHidden
						id={name}
						ref={ref}
						required={required}
						name={name}
						type="file"
						accept="image/*"
						{...props}
					/>
				</SmallPageSection>
			</SmallPageSection>
		)
	}),
)

ImageInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string.isRequired, // Main identifier. Needs to be unique for the form
	desiredName: PropTypes.string, // Set to e.g. "firstName" if another input with "firstName" already exists
	onChange: PropTypes.func,
	required: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	skipRequiredAsterisk: PropTypes.bool,
}

export default ImageInput
