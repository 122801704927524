import React from 'react'
import styled from 'styled-components'

const StyledSvgIcon = styled.svg`
	fill: currentColor;
	display: inline-block;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	user-select: none;
	flex-shrink: 0;
	width: ${props => (props.width ? `${parseInt(props.width)}px` : '24px')};
	height: ${props => (props.height ? `${parseInt(props.height)}px` : '24px')};
	${({ onClick }) => onClick && 'cursor: pointer;'}
	${({ fill }) => (fill ? `> svg {fill: ${fill};}` : '')}
`

const SvgShell = ({ children, viewBox, ...props }) => (
	<StyledSvgIcon viewBox={viewBox ? viewBox : '0 0 24 24'} {...props}>
		{children}
	</StyledSvgIcon>
)

export default SvgShell
