import React from 'react'
import SvgShell from './SvgShell'

const Diamond = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.83 9.75">
			<path
				style={{
					fill: props.fill || 'currentColor',
				}}
				d="M5.5,10.38h0a.47.47,0,0,1-.22-.09l0,0-5-6a.8.8,0,0,1-.08-.16v0A.34.34,0,0,1,.14,3.8h0L2.12,1l0,0A.4.4,0,0,1,2.37.85h0L5.47.63h.06L8.59.84h.05A.41.41,0,0,1,8.88,1l2,2.79h0a.5.5,0,0,1,.06.24.13.13,0,0,0,0,.06.64.64,0,0,1-.08.14l-5,6,0,0a.41.41,0,0,1-.11.07.19.19,0,0,1-.1,0Zm-2.2-6L5.5,9.11,7.7,4.37Zm5.23,0L7,7.6,9.74,4.37Zm-7.27,0L4,7.6,2.47,4.37ZM8.84,2.25,8.7,3.63H9.82ZM5.5,1.64l-1.69,2H7.19Zm-3.34.61-1,1.38H2.3ZM6.3,1.42,8,3.38l.18-1.83Zm-1.6,0-1.85.13L3,3.38Z"
				transform="translate(-0.08 -0.63)"
			/>
		</svg>
	</SvgShell>
)

export default Diamond
